import { createGlobalStyle } from 'styled-components';
import { toLanguageFromLocale } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize/models/locale';

type MetaFields = {
  [key: string]: string | number | boolean | null;
};

type ResetParams = {
  greeting?: string;
};

declare global {
  interface Window {
    adaEmbed?: {
      reset: (resetParams?: ResetParams) => Promise<void>;
      toggle: () => Promise<void>;
      getInfo: () => Promise<{
        isChatOpen: boolean;
      }>;
      setMetaFields: (fields: MetaFields) => Promise<void>;
    };
    adaSettings?: Record<string, any>;
  }
}

export const ADA_SETTINGS_SCRIPT_ID = '__ada-settings';

export const ADA_BUTTON_SELECTOR = '#ada-button-frame, #ada-chat-frame';

const toAdaSettings = (locale: Locale) => {
  return {
    language: toLanguageFromLocale(locale),
  };
};

export const toAdaSettingsString = (locale: Locale) => {
  return `window.adaSettings = ${JSON.stringify(toAdaSettings(locale))}`;
};

export const toAdaScriptProps = (botHandle: string) => {
  return {
    id: '__ada',
    'data-handle': botHandle,
    src: 'https://static.ada.support/embed2.js',
  };
};

export const injectAdaScript = (locale: Locale, botHandle: string) => {
  window.adaSettings = toAdaSettings(locale);

  const chatScript = document.createElement('script');
  const scriptProps = toAdaScriptProps(botHandle);
  for (const [key, value] of Object.entries(scriptProps)) {
    chatScript.setAttribute(key, value);
  }

  document.body.appendChild(chatScript);
};

export const getAda = () => window.adaEmbed;

export const adaExists = () => Boolean(getAda());

export const setAdaMetaFields = (fields: MetaFields) => {
  return window.adaEmbed?.setMetaFields(fields);
};

export const openAdaChat = async (answerId?: string) => {
  const ada = window.adaEmbed;

  if (!ada) {
    return;
  }

  const { isChatOpen } = await ada.getInfo();

  if (answerId) {
    await ada.reset({ greeting: answerId });
  }

  if (isChatOpen) {
    return;
  }

  await ada.toggle();
};

export const HideAdaChatButton = createGlobalStyle`
  ${ADA_BUTTON_SELECTOR} {
    display: none;
  }
`;
